import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react"
import { useWeb3Modal } from "@web3modal/wagmi/react"

import { WagmiConfig } from "wagmi"
import { mainnet, goerli, sepolia } from "viem/chains"
import { useAccount } from "wagmi"

// 1. Get projectId
const projectId = "ac4eb13988c66a331f42f1e1ef0c1024"

// 2. Create wagmiConfig
const metadata = {
    name: "Web3Modal",
    description: "Web3Modal Example",
    url: "https://mferscoin.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
}

const chains = [goerli]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({ wagmiConfig, projectId, chains })

export function withHome(Component): ComponentType {
    return (props) => {
        return (
            <WagmiConfig config={wagmiConfig}>
                <Component {...props} />
            </WagmiConfig>
        )
    }
}

export function withWalletConnection(Component): ComponentType {
    return (props) => {
        const { address, isConnecting, isDisconnected } = useAccount()
        return !address && <Component {...props} />
    }
}
